import React from "react"

const bankOrCreditUnion = ({ jsontld }) => {
    const name = jsontld.data.name;
    const phone = jsontld.data.phone;
    const address = jsontld.data.address;
    //const url = jsontld.data.url;
    // const ratingValue = props.data.ratingValue;
    // const reviewCount = props.data.reviewCount;
    const image = jsontld.data.image;

    const ldjson = {
        "@context": "http://schema.org",
        "@type": "BankOrCreditUnion",
        "name": name,
        "telephone": phone,
        "address": address,
        // "aggregateRating": {
        //     "@type": "AggregateRating",
        //     "ratingValue": {ratingValue},
        //     "reviewCount": {reviewCount}
        // },
        "image": image
    };

    return (
        <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: JSON.stringify(ldjson) }}
        />
    )
}

export default bankOrCreditUnion