import React, { Component } from "react";
import Layout from "../../components/layout/layout.js";
import Module from "../../components/structure/Module/module.js";
import Wrapper from "../../components/structure/Wrapper/wrapper.js";
import Column from "../../components/structure/Column/column.js";
import Box from "../../components/modules/Box/box.js";
import Seo from "../../components/seo/seo.js";
import { Title } from "../../components/elements/Title/title";
import { Breadcrumbs } from "../../components/modules/Breadcrumbs/breadcrumbs";
import { Rating } from "../../components/modules/Rating/rating";
import RichText from "../../components/modules/RichText/richText.js";
import Image from "../../components/modules/Image/image.js";
import Faq from "../../components/modules/Faq/faq";
import WidgetBanks from "../../components/modules/WidgetBanks/widgetBanks.js";
import { Table } from "../../components/modules/Table/table.js";
import { Button } from "../../components/elements/Button/button";
import JsonBreadcrumbs from "../../components/seo/ld-json/jsonBreadcrumbs.js";
import BankOrCreditUnion from "../../components/seo/ld-json/bankOrCreditUnion.js";
import OtherProduct from "../../components/modules/OtherProduct/otherProduct.js";
import { CardsOther } from "../../components/modules/CardsOther/cardsOther";
import { Opinions } from '../../components/modules/Opinions/opinions';
import AdsenseManager from '../../components/modules/Adsense/AdsenseManager'

class Bank extends Component {

    render() {
        const dataBank = this.props.pageContext.data;
        const seo = this.props.pageContext.seo;
        const textStatic = this.props.pageContext.textStatic;
        const rating = true;//this.props.pageContext.rating || false;
        const country_code = seo.country_code;
        const iconMark = require('./icons/icon-mark.png');
        const mainText = this.props.pageContext.mainText || false;
        const altScreenText = seo.altScreenText || false;
        const urlOfficesText = seo.urlOfficesText || false;
        const asks = this.props.pageContext.asks || false;
        const otherAccount = this.props.pageContext.otherAccount;
        const otherCard = this.props.pageContext.otherCard;
        const productMeta = this.props.pageContext.productMeta;
        const opinionsCurrentSourceName = dataBank.name;
        const opinionsCurrentSourceUrl = seo.url + "/";
        const adsense = this.props.pageContext.adsense;

        const jsontld = {
            "data": {
                "name": `${dataBank.name}`,
                "phone": `${dataBank.phone}`,
                "email": `${dataBank.email}`,
                "address": `${dataBank.address}`,
                "url": `${dataBank.url_home}`,
                "image": `${seo.image}`,
            }
        };

        const tableInfo = [
            [textStatic.tab_main_info_bank_name, dataBank.name],
            [textStatic.tab_main_info_bank_address, dataBank.address],
            [textStatic.tab_main_info_bank_swift, dataBank.swift],
            [textStatic.tab_main_info_bank_phone, dataBank.phone],
            [textStatic.tab_main_info_bank_url, dataBank.url_home],
            [textStatic.tab_main_info_bank_online_banking, dataBank.url_cabinet]
        ];

        return (
            <Layout>
                <Seo lang={seo.lang} title={seo.title} url={seo.url} image={seo.image} description={seo.description} robotsIndex={seo.robotsIndex} robotsFollow={seo.robotsFollow}/>
                <Module bgPr>
                    <Wrapper white>
                        <Breadcrumbs content={dataBank.breadcrumbs} host={seo.host}/>
                        <JsonBreadcrumbs data={dataBank.breadcrumbs} host={seo.host}/>

                        <Column row>
                            <Column col="fifth" colSm="half" colXs="full">
                                <Image filename={dataBank.image} alt={dataBank.name} />
                            </Column>
                            <Column col="fifth-complete" colSm="half" colXs="full">
                                <Title tag={`h1`} size35 content={dataBank.name}/>
                            </Column>
                        </Column>
                        <Column row>
                            <Column row col="forth-complete" colSm="full">
                                {rating && (
                                    <Column colXs="full" col="half">
                                        <Rating
                                            textStatic={textStatic}
                                            country_code={country_code}
                                            object_name={"bank"}
                                            object_id={dataBank.id}
                                            jsontld_parent={jsontld}
                                            jsontld_parent_type="BankOrCreditUnion"
                                        />
                                    </Column>
                                )}
                                <Column textRight xsTextLeft colXs="full" col="half">
                                    <Button className={`marg-b10`} widthIcon={`20`} icon={iconMark} link={`/${dataBank.url_offices}/`} content={urlOfficesText}  />
                                </Column>
                            </Column>
                            <Column col="forth-complete" colSm="full">
                                <Box bgPr borderTop>
                                    <Title tag={`h2`} size20 content={textStatic.tab_main_info} />
                                    <Table info firstColStrong dataBody={tableInfo} />
                                    <RichText className={`marg-t30`} content={mainText} />

                                    <AdsenseManager adsense={adsense}/>

                                    <Image className={`marg-b32`} filename={dataBank.image_screen} alt={altScreenText}/>

                                    <Faq asks={asks} title={textStatic.faq_title}/>

                                    {otherAccount && (
                                        <OtherProduct type={"account"} title={textStatic.bank_account_accounts_title} products={otherAccount}/>
                                    )}

                                    {otherCard && (
                                        <CardsOther dataCards={otherCard} title={textStatic.card_title} productMeta={productMeta} textStatic={textStatic}/>
                                    )}

                                    <Opinions
                                        textStatic={textStatic}
                                        defaultCurrentSourceName={opinionsCurrentSourceName}
                                        defaultCurrentSourceUrl={opinionsCurrentSourceUrl}
                                        seo={seo}
                                        object_name={"bank"}
                                        country_code={country_code}
                                        object_id={dataBank.id}
                                    />

                                    <AdsenseManager adsense={adsense}/>
                                </Box>
                            </Column>
                            <Column col="forth" colSm="full">
                                <Box bgPr>
                                    <WidgetBanks/>
                                </Box>
                            </Column>
                        </Column>
                    </Wrapper>
                </Module>
                <BankOrCreditUnion jsontld={jsontld}/>
            </Layout>
        );

    }
}

export default Bank;